import React, {useState} from "react"
import SEO from "../components/seo";
import ScrollBar from "../components/scrollBar/scrollBar";
import Header from "../components/header/header";
// import {navigate} from "gatsby";
import "../styles/index.scss";

import * as styles from "../components/mailchimp/mailchimp.module.scss";
import {Link} from "gatsby";
// import style from "../components/ui/btn/uiBtn.module.scss";

const BookPage = () => {
    const [isNew, setIsNew] = useState(false)
    return (
        <div className='app' id='top'>
            <SEO title="AcuBoston"/>
            <ScrollBar/>
            <Header noBook={true}/>
            <main className='main'
                  style={{
                      paddingTop: "70px",
                      textAlign: "center",
                      fontSize: "21px"
                  }}>
                <p style={{
                    marginBottom: "20px",
                    color: "#4bad34FF",
                    fontWeight: "bold",
                    fontsize: "30px"
                }}
                >Are you a new or existing patient?</p>

                <p style={{marginBottom:"40px"}}>
                    {/*Please choose your answer and push correct button below.*/}
                </p>

                <div className={styles.mailchimpForm}>
                    <Link className={styles.button}
                          style={{textDecoration: 'none', display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '360px'}}
                          to="/booknew/">New patient</Link>

                    <a className={styles.button}
                       style={{textDecoration: 'none', display: 'block', marginLeft: 'auto', marginRight:'auto', width: '360px'}}
                        href={"https://acuboston.janeapp.com/#/discipline/1/treatment/1"}
                        target={"_blank"}
                        onClick={(e) => console.log('log gtag', props.name, window.gtag('event', 'page_view', {'page_title': props.name}))}
                        rel={'noopener noreferrer'}
                    >
                        Existing patient
                    </a>
                </div>
            </main>
        </div>
    )
}

export default BookPage
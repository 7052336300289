import React from "react";
// import UiBtn from "../../ui/btn/uiBtn"
import style from './headerBtn.module.scss'
import btnStyle from '../../ui/btn/uiBtn.module.scss'
import {Link} from "gatsby";

export const HeaderBtn = () => {

  return (
    <div className={style.headerBtnWrap}>
        <Link className={`${btnStyle.btn} ${style.headerBtn}`} to="/book/">BOOK ONLINE</Link>
      {/*<UiBtn className={style.headerBtn} href="https://acuboston.janeapp.com/#/discipline/1/treatment/1"*/}
      {/*       name="book"*/}
      {/*       target="_blank" rel='noopener noreferrer'>*/}
      {/*  BOOK ONLINE*/}
      {/*</UiBtn>*/}
    </div>
  )
}

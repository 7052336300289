import React, { useEffect, useState } from "react";
import style from "./scrollBar.module.scss";

function ScrollBar() {
  const [newProgressHeight, setNewProgressHeight] = useState(0);

  useEffect(() => {
    let handlerScroll = () => {
      const totalPageHeight = document.body.scrollHeight - window.innerHeight;
      const position = (window.pageYOffset / totalPageHeight) * 100;
      setNewProgressHeight(position);
    }

    window.addEventListener("scroll", handlerScroll)
    return () => window.removeEventListener("scroll", handlerScroll)
  })

  return (
    <>
      <div className={style.progressBar} style={{
        width: `${newProgressHeight}%`,
        opacity: `${newProgressHeight}%`,
      }}/>

      <div className={style.progressBarContainer}/>
    </>
  )
}

export default ScrollBar
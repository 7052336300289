import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import style from "./mainBanner.module.scss"
import free from "./img/free.webp"
import free_md from "./img/free_md.webp"
import free_lg from "./img/free_lg.webp"
import free_xl from "./img/free_xl.webp"
import home from "./img/home.webp"
import home_md from "./img/home-md.webp"
import home_lg from "./img/home-lg.webp"
import home_xl from "./img/home-xl.webp"

import free_png from "./img/free.png"
import free_png_md from "./img/free_md.png"
import free_png_lg from "./img/free_lg.png"
import free_png_xl from "./img/free_xl.png"
import home_png from "./img/home.png"
import home_png_md from "./img/home-md.png"
import home_png_lg from "./img/home-lg.png"
import home_png_xl from "./img/home-xl.png"

function MainBanner() {
  return (
    <div className={style.mainBanner}>
      <div className={style.mainBanner__home}>
        <picture>
          <source media="(min-width: 1140px)" srcSet={home_xl} type="image/webp"/>
          <source media="(min-width: 1140px)" srcSet={home_png_xl} type="image/png"/>

          <source media="(min-width: 960px)" srcSet={home_lg} type="image/webp"/>
          <source media="(min-width: 960px)" srcSet={home_png_lg} type="image/png"/>

          <source media="(min-width: 720px)" srcSet={home_md} type="image/webp"/>
          <source media="(min-width: 720px)" srcSet={home_png_md} type="image/png"/>

          <source media="(max-width: 719px)" srcSet={home} type="image/webp"/>
          <img className={style.mainBanner__homeImg} src={home_png} alt="main banner"/>
        </picture>
      </div>
      <div className={`${style.mainBanner__body} container`}>
        <h1 className={style.mainBanner__bodyTitle}>ANCIENT KNOWLEDGE MEETS MODERN HEALING</h1>
        <p className={style.mainBanner__bodyText}>acupuncture & herbal medicine</p>
      </div>

    </div>
  )
}

export default MainBanner
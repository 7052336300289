import React, { useEffect, useState } from "react"
import styles from "./header.module.scss"
import { HeaderBtn } from "./headerBtn/headerBtn"
import { HeaderLogo } from "./headerLogo/headerLogo"
import { MobileMenu } from "../mobileMenu/mobileMenu"
import MenuNavBar from "../menuNav/menuNavBar"

function Header({noBook}) {
  const [showMenu, setShowMenu] = useState(false);

  useEffect( ()=> {
    const body = document.body;
    if (showMenu) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [showMenu]);

  return (
    <header className={styles.header}>
      <div className={`${styles.headerBody} container`}>
        <HeaderLogo/>
          { !noBook &&
            <HeaderBtn/>
          }

        <div className={!showMenu ? styles.headerBurger : `${styles.headerBurgerActive} ${styles.headerBurger}`}
             onClick={() => setShowMenu((prevState => !prevState))}>
          <span className={styles.burger}/>
        </div>
      </div>

      <div className={`${styles.headerNav} container`}>
        <MenuNavBar/>
      </div>

      <div onClick={() => setShowMenu(false)}>
        <MobileMenu active={showMenu}/>
      </div>
    </header>
  )
}

export default Header
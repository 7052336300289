import React from "react"
import SEO from "../components/seo";
import ScrollBar from "../components/scrollBar/scrollBar";
import Header from "../components/header/header";
import * as styles from "../components/mailchimp/mailchimp.module.scss";
import {navigate} from "gatsby";

const GthxPage = () => {
    return (
        <div className='app' id='top'>
            <SEO title="AcuBoston"/>
            <ScrollBar/>
            <Header/>
            <main className='main'
                  style={{
                      padding: "70px",
                      textAlign: "center",
                      fontSize: "21px"
                  }}>
                <p style={{
                    marginBottom: "20px",
                    color: "#4bad34FF",
                    fontWeight: "bold",
                    fontsize: "30px"
                }}>Thanks for your message!</p>
                <p style={{marginBottom:"50px"}}>We'll get back to you as soon as possible.</p>

                <div className={styles.mailchimpForm}>
                    <button onClick={(e) => {
                        e.preventDefault();
                        navigate('/')
                    }}>
                        BACK
                    </button>
                </div>

            </main>
        </div>
    )
}

export default GthxPage
import React, { FC } from "react";
import styles from "./mobileMenu.module.scss";
import { MobileMenuBtn } from "./mobileMenuBtn/mobileMenuBtn";
import { MobileMenuNav } from "./mobileMenuNav/mobileMenuNav";

type PropsType = {
  active: boolean
}

export const MobileMenu:FC<PropsType> = ({active}:PropsType) => {

  return (
    <div
      className={active ? styles.mobileMenu : `${styles.mobileMenuHide } ${styles.mobileMenu}`}
    >
      <MobileMenuNav/>
      <MobileMenuBtn/>
    </div>
  )
}
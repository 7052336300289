import React from "react";
import style from "./mobileMenuBtn.module.scss";
// import UiBtn from "../../ui/btn/uiBtn"
import {Link} from "gatsby";
import btnStyle from "../../ui/btn/uiBtn.module.scss";

export const MobileMenuBtn = () => {

  return (
    <div className={style.mobileMenu__btn}>
    <Link className={`${btnStyle.btn} ${style.mobileMenu__btnBook}`} to="/book/">BOOK ONLINE</Link>
        <br/><br/><br/>
      {/*  <UiBtn className={style.mobileMenu__btnBook} href="https://acuboston.janeapp.com/#/discipline/1/treatment/1" target="_blank" rel='noopener noreferrer'>*/}
      {/*  BOOK ONLINE*/}
      {/*</UiBtn>*/}
      {/*<UiBtn className={style.mobileMenu__btnTelemedicine} href="https://acuboston.janeapp.com/#/discipline/2/treatment/9" target="_blank" rel='noopener noreferrer'>*/}
      {/*  TELEMEDICINE*/}
      {/*</UiBtn>*/}
    </div>
  )
}
import React, {Suspense, lazy} from "react";
import SEO from "../components/seo";
import Header from "../components/header/header";
import AboutAuthor from "../components/aboutAuthor/aboutAuthor";
import "../styles/index.scss";
import ScrollBar from "../components/scrollBar/scrollBar";
import MainBanner from "../components/mainBanner/mainBanner";

const Acupuncture = lazy(() => import("../components/acupuncture/acupuncture"))
const CommunityAcupuncture = lazy(() => import("../components/communityAcupuncture/communityAcupuncture"))
const ConditionsList = lazy(() => import("../components/conditionsList/conditionsList"))
const Therapies = lazy(() => import("../components/therapies/therapies"))
const Expect = lazy(() => import("../components/expect/expect"))
const ResultTherapies = lazy(() => import("../components/resultTherapies/resultTherapies"))
const HerbalMedicine = lazy(() => import("../components/herbalMedicine/herbalMedicine"))
const Insurance = lazy(() => import("../components/insurance/insurance"))
const Footer = lazy(() => import("../components/footer/footer"))
const Map = lazy(() => import("../components/map/map"))
const Fees = lazy(() => import("../components/fees/fees"))
const Contact = lazy(() => import("../components/contact/contact"))
const Mailchimp = lazy(() => import("../components/mailchimp/mailchimp"))
const InfraredSauna = lazy(() => import("../components/InfraredSauna/InfraredSauna"))

const IndexPage = () => {
    const isSSR = typeof window === "undefined"

    const createLeadConnect  = () => {
        return { __html: `<script src="https://widgets.leadconnectorhq.com/loader.js" data-resources-url="https://widgets.leadconnectorhq.com/chat-widget/loader.js" data-widget-id="66b0d7f592c831304cb2bf38"></script>` }
    }

    return (
        <div className='app' id='top'>
            <SEO title="AcuBoston"/>
            <ScrollBar/>
            {/*===========HEADER=============*/}
            <Header/>
            <main className='main'>
                {/*============HOME==============*/}
                <MainBanner/>
                {!isSSR && (
                    <>
                        {/*/!*=========ACUPUNCTURE==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <AboutAuthor/>
                        </Suspense>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Acupuncture/>
                        </Suspense>
                        {/*/!*=========CONDITIONS==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <ConditionsList/>
                        </Suspense>
                        {/*/!*=========Therapies==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Therapies/>
                        </Suspense>
                        {/*/!*=========Expect==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Expect/>
                        </Suspense>
                        {/*/!*=========ResultTherapies==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <ResultTherapies/>
                        </Suspense>
                        {/*/!*=========CommunityAcupuncture==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <CommunityAcupuncture/>
                        </Suspense>
                        {/*/!*=========InfraredSauna==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <InfraredSauna/>
                        </Suspense>
                        {/*/!*=========HerbalMedicine==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <HerbalMedicine/>
                        </Suspense>
                        {/*/!*=========PRICING & INSURANCE==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Insurance/>
                        </Suspense>
                        {/*/!*=========SERVICE FEES==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Fees/>
                        </Suspense>
                        {/*/!*=========CONTACTS==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Contact/>
                        </Suspense>
                        {/*/!*=========CONTACTS==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Mailchimp/>
                        </Suspense>
                        {/*/!*=========Google map Brookline==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Map title={"AcuBoston Brookline"}
                                 location={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1476.0405766134768!2d-71.0794403!3d42.276791!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e379c807af181f%3A0x669a18373420c559!2s95%20Verndale%20St%2C%20Brookline%2C%20MA%2002446%2C%20USA!5e0!3m2!1sen!2sru!4v1603448989052!5m2!1sen!2sru'}/>
                        </Suspense>
                        {/*/!*=========Google map Dedham==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Map title={"AcuBoston Dedham"}
                                 location={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2953.4710297271668!2d-71.15597048451724!3d42.24711265021931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37fae5466604f%3A0x9ff5fe1e98804c5e!2s16%20Milton%20St%2C%20Dedham%2C%20MA%2002026%2C%20USA!5e0!3m2!1sen!2sby!4v1665416736080!5m2!1sen!2sby'}/>
                        </Suspense>
                        {/*/!*=========Footer==========*!/*/}
                        <Suspense fallback={<div>Loading...</div>}>
                            <Footer/>
                        </Suspense>
                    </>
                )}
            </main>
            <div dangerouslySetInnerHTML={createLeadConnect()}/>
        </div>
    )
}

export default IndexPage

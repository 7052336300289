import React, { useEffect, useState } from "react";
import style from "./headerLogo.module.scss";
import logo_png from "./img/logo.png";
import logo from "./img/logo.webp";
import logo_xl_png from "./img/logo_xl.png";
import logo_xl from "./img/logo_xl.webp";

export const HeaderLogo = () => {
  const [lowLogo, setLowLogo] = useState(false)

  useEffect(() => {
    const handlerScroll = () => {
      if (window.pageYOffset) {
        setLowLogo(true)
      }
      else setLowLogo(false)
    }
    window.addEventListener("scroll", handlerScroll)
    return () => window.removeEventListener("scroll", handlerScroll)
  })

  return (
    <div className={style.headerLogo}>
      <picture>
        <source media="(min-width:1140px)" srcSet={logo_xl_png} type="image/png"/>
        <source media="(min-width:1140px)" srcSet={logo_xl} type="image/webp"/>
        {/*<source media="(min-width:960px)" srcSet={lowLogo ? logo_low : logo_xl}/>*/}
        {/*<source media="(min-width:992px)" srcSet="./img/logo-lg.png"/>*/}
        {/*<source media="(min-width: 768px)" srcSet="./img/logo-md.png"/>*/}
        <source media="(max-width:1139px)" srcSet={logo} type="image/webp"/>
        <img className={!lowLogo ? style.headerLogo__img : `${style.headerLogo__img} ${style.headerLogo__imgLow}`}
             src={logo_png} alt="logo" width="237px" height="62px"/>
      </picture>
    </div>
  )
}
import React, {useState} from "react"
import SEO from "../components/seo";
import ScrollBar from "../components/scrollBar/scrollBar";
import Header from "../components/header/header";
import {navigate} from "gatsby";
import "../styles/index.scss";
import * as styles from "../components/mailchimp/mailchimp.module.scss";

const BookPage = () => {
    const [isNew, setIsNew] = useState(false)
    return (
        <div className='app' id='top'>
            <SEO title="AcuBoston"/>
            <ScrollBar/>
            <Header  noBook={true}/>
            <main className='main'
                  style={{
                      padding: "70px",
                      textAlign: "center",
                      fontSize: "21px"
                  }}>
                <p style={{
                    marginBottom: "20px",
                    color: "#4bad34FF",
                    fontWeight: "bold",
                    fontsize: "30px"
                }}
                >Give us a call!</p>

                <p style={{marginBottom:"50px"}}>
                    Please call <a href='tel:+16178602039'>617-860-2039</a> to book your first appointment.
                </p>

                <div className={styles.mailchimpForm}>

                    <button onClick={(e) => {
                        e.preventDefault();
                        navigate('/')
                    }}>
                        BACK
                    </button>

                </div>

            </main>
        </div>
    )
}

export default BookPage
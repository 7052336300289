// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-book-jsx": () => import("./../../../src/pages/book.jsx" /* webpackChunkName: "component---src-pages-book-jsx" */),
  "component---src-pages-booknew-jsx": () => import("./../../../src/pages/booknew.jsx" /* webpackChunkName: "component---src-pages-booknew-jsx" */),
  "component---src-pages-gthx-jsx": () => import("./../../../src/pages/gthx.jsx" /* webpackChunkName: "component---src-pages-gthx-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ithx-jsx": () => import("./../../../src/pages/ithx.jsx" /* webpackChunkName: "component---src-pages-ithx-jsx" */),
  "component---src-pages-ivf-jsx": () => import("./../../../src/pages/ivf.jsx" /* webpackChunkName: "component---src-pages-ivf-jsx" */)
}


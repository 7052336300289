import React from "react";
import "../styles/index.scss";

import SEO from "../components/seo";
import Header from "../components/header/header";
import ScrollBar from "../components/scrollBar/scrollBar";

const IvfPage = () => {
    return (
        <div className='app' id='top'>
            <SEO title="AcuBoston"/>
            <ScrollBar/>
            <Header/>
            <main className='main'>
                <iframe
                    src="https://link.trustdrivencare.com/widget/form/31tmgx6jzadzdSJUqad3"
                    style={{width: "100%", height: "100%", border: "none", borderRadius: "3px"}}
                    id="inline-31tmgx6jzadzdSJUqad3"
                    data-layout="{'id':'INLINE'}"
                    data-trigger-type="alwaysShow"
                    data-trigger-value=""
                    data-activation-type="alwaysActivated"
                    data-activation-value=""
                    data-deactivation-type="neverDeactivate"
                    data-deactivation-value=""
                    data-form-name="Acupuncture Insurance Verification"
                    data-height="1787"
                    data-layout-iframe-id="inline-31tmgx6jzadzdSJUqad3"
                    data-form-id="31tmgx6jzadzdSJUqad3"
                    title="Acupuncture Insurance Verification"
                />
                <script src="https://link.trustdrivencare.com/js/form_embed.js"></script>
            </main>
        </div>
)
}

export default IvfPage